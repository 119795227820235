header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 156px;
  padding: 0 2rem;
  background-color: #ffffff;
  overflow: hidden;
  width: 100vw;
  z-index: 100000;
  top: 0;
  left: 0;
  right: 0;
  position: relative;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.1);
  overflow: auto;
  white-space: nowrap;
  flex-shrink: 0;
}

.arabic-language header {
  direction: rtl;
}

#nav .link {
  margin: 0 1rem;
  text-decoration: none;
  color: #555555;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}

#nav .link:hover {
  color: #2f80ed;
}

header div,
#nav {
  display: flex;
  align-items: center;
}

#nav .active {
  color: #2f80ed;
  border-bottom: 2px solid #2f80ed;
  text-align: center;
  line-height: 40px;
}

.logo {
  cursor: pointer;
}

header .pi {
  font-size: 20px;
  color: #000;
}

#popup_menu {
  z-index: 100000 !important;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
}

.p-slidemenu-wrapper {
  height: 130px !important;
}

.p-slidemenu .p-slidemenu-content {
  overflow-y: hidden !important;
}

.p-menu .p-submenu-header {
  display: none;
}

header .p-button {
  box-shadow: 0 0 !important;
}

.dropdown-user-email {
  font-size: 14px;
  text-transform: capitalize;
}

.dropdown-user-email span {
  font-weight: 700 !important;
}

.p-menuitem-text {
  font-weight: 400 !important;
}

.p-button.p-button-icon-only {
  padding: 0 20px !important;
}

.check-hidden {
  visibility: hidden;
}

@media screen and (max-width: 920px) {
  header {
    gap: 10px;
  }

  header::-webkit-scrollbar {
    display: none;
  }
}
