.services-header {
  width: 50px;
}

.services-main-column {
  width: 100%;
}

.services-list-features-not-allowed {
  visibility: hidden;
}
