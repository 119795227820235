.profile-card-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 40px 30px;
}
.profile-card-container .p-error {
  position: relative;
  top: 8px;
}

.profile-card-container .row {
  line-height: 0;
}

.close-profile-card-form {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  cursor: pointer;
}

.profile-picture-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sb-avatar__text {
  border-radius: 50%;
}

.profile-card-input {
  width: 47%;
  height: 28px;
  background: #ffffff;
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  color: #555555;
}

.birthday-calendar {
  height: 27px;
  width: 47%;
}

.profile-card-input:focus {
  outline: none;
}

.full-row {
  width: 100%;
  justify-content: center;
}

#gender {
  font-size: 16px;
  line-height: 20px;
  color: #555555;
  /* padding-left: 10px; */
  align-self: flex-start;
}

.gender-row {
  flex-direction: column;
  gap: 10px;
}

.gender-row button {
  padding: 12px 30px;
  width: 109px;
  height: 44px;
  background: #f1f1f1;
  border-radius: 20px;
  border: 0;
  text-align: center;
  color: #555555;
  cursor: pointer;
}

.gender-buttons {
  justify-content: flex-start;
  padding: 0;
}

.gender-buttons .selected {
  background: #ffd800;
  color: #004a60;
  transition: 0.3s;
}

#profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 15px;
  margin-bottom: 10px;
}

.profile-card-container .p-inputtext {
  border: 0 !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #c4c4c4 !important;
}

#cities-dropdown {
  width: 47%;
}

#cities-dropdown .p-inputtext {
  border: 0 !important;
}

.full-width,
.full-width input,
.profile-card-container .full-width .p-calendar,
.full-width .PhoneInput,
#cities-dropdown {
  width: 100% !important;
}

#customer-birthday {
  width: 100% !important;
}

.edit-profile-avatar .sb-avatar__text {
  color: #000 !important;
}

.change-password-button {
  font-size: 12px;
  text-decoration: underline;
  color: #0202d7;
  cursor: pointer;
}
.delete-company-button {
  color: red !important;
  width: 40px !important;
  height: 25px !important;
  padding: 0 !important;
}
.companies-input-no-border .p-dropdown .p-dropdown-label {
  border-bottom: 0px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.companies-dropdown {
  width: calc(100% - 40px);
}

@media screen and (max-width: 1000px) {
  .general {
    flex-direction: column;
    height: fit-content !important;
  }
  .general-tab-left-panel {
    display: flex;
    margin-left: 0 !important;
  }

  .general-tab-right-panel {
    margin-right: 0 !important;
  }
}

@media screen and (max-width: 500px) {
  .general {
    height: fit-content !important;
  }

  .profile-card-container .row {
    flex-direction: column;
  }

  .profile-card-container .gender-buttons {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
