.not-authorized-container {
  display: flex;
  position: absolute;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

#authorization {
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
  color: #000;
  cursor: pointer;
  margin-top: 156px;
  padding: 0 40px;
  text-align: center;
}
