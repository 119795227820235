@media screen and (max-width: 900px) {
  .services-tab-container .p-tabview-nav {
    flex-wrap: wrap;
  }
  .services-tab-container .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 10px !important;
    padding-left: 0 !important;
  }
  .promotion-codes-tab {
    padding-bottom: 20px;
  }
}

.arabic-language .services-tab-container .table-header {
  justify-content: flex-end;
}
