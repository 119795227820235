.membership-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.add-membership {
  margin-top: 0 !important;
  padding: 0px 40px;
}

.membership-form {
  padding: 40px 40px 0 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 35px;
  width: 70%;
  margin: 0 auto;
  margin-top: 5px;
}

.membership-form .form-input {
  border: 0 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  border-radius: 0 !important ;
}

.default-membership-container {
  justify-content: flex-start;
}

.add-new-service-button {
  width: fit-content;
  padding: 12px 20px;
  border-radius: 30px;
  background: #fff;
  border: 1px solid #56ccf2;
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  font-size: 13px;
  color: #56ccf2;
  display: flex;
  align-items: center;
  gap: 10px;
}

.membership-close-button {
  cursor: pointer;
}

.service-dropdown-title {
  color: gray;
  cursor: not-allowed;
}

#delete-service-button {
  width: 15px;
  position: relative;
  top: 12px;
  cursor: pointer;
}

.row-full-width {
  width: 100%;
  line-height: 0;
}

.membership-form .p-error {
  position: relative;
  top: 10px;
}

.membership-form .close-button-container {
  justify-content: space-between;
  align-items: center;
}

.memberships-form-features-not-allowed {
  display: none;
}

.membership-form input[role="spinbutton"] {
  width: 100% !important;
}

.arabic-language .add-new-service-button {
  flex-direction: row-reverse;
}

@media screen and (max-width: 890px) {
  .membership-form .row {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .membership-form #delete-service-button {
    top: 0;
  }

  .default-membership-container {
    flex-direction: row !important;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 750px) {
  .default-membership-container .p-checkbox {
    align-self: flex-start;
  }

  .membership-form {
    width: 100%;
  }

  .add-membership {
    padding: 2px !important;
  }
}

@media screen and (max-width: 670px) {
  .membership-form .form-title {
    font-size: 26px;
  }

  .membership-form #close-card-form-button {
    padding: 0;
  }

  .membership-form .close-button-container {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 430px) {
  .membership-form .close-button-container .form-title,
  .membership-form .close-button-container img {
    font-size: 20px;
    width: 20px;
  }
}

@media screen and (max-height: 900px) {
  .add-membership {
    height: fit-content !important;
  }
}
