.car-card {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  height: fit-content;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  min-width: 40%;
  padding-left: 0;
  display: flex;
  width: 100%-20px;
  margin: 0px 20px 0px 20px;
  margin-right: 20px;
}

.car-card .row {
  width: 100%;
  padding: 15px 15px;
  align-self: center;
  background-color: transparent;
}

#car-img {
  width: 400px;
  height: 220px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  align-self: center;
}

#car-color-license-plate {
  font-weight: 400;
}

.car-info-container {
  height: 100px;
  background-color: #e9e9e9;
  flex-wrap: wrap;
}

.car-info-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100px;
  text-align: center;
}

.car-info-column.registration-photo {
  width: 120px;
}

.view-registration-photo-link,
.view-registration-photo-link:visited {
  color: black;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.car-info-title {
  color: #828181;
  width: max-content;
}

.car-tab-container .carCardButton {
  margin-right: 20px;
}
@media screen and (max-height: 900px) {
  .car-tab-container {
    height: fit-content;
  }
}

@media screen and (max-width: 500px) {
  .car-info-container {
    height: fit-content;
    width: fit-content;
  }

  #car-img {
    width: 300px;
    height: 180px;
  }

  .car-card {
    width: 100%;
    margin: 0;
    flex-direction: column;
  }
  .car-tab-container .carCardButton {
    margin-right: 0;
  }
}

@media screen and (max-width: 750px) {
  .car-info-container {
    height: fit-content;
  }

  #car-img {
    width: 250px;
    height: 150px;
  }
}
