.custom-membership-form {
  width: 60%;
  margin: 20px auto;
  padding: 20px 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.custom-membership-form .p-error {
  position: relative;
}

.custom-membership-calender {
  width: 100%;
}

.custom-membership-calender input {
  border: 0;
  border-bottom: 1px solid #c4c4c4;
  border-radius: 0;
  height: 26px;
}

.custom-membership-calender input:hover {
  border: 0 !important;
  border-bottom: 1px solid #c4c4c4 !important;
}

.custom-membership-services-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-name {
  min-width: 160px;
}

.custom-membership-labels-container {
  font-weight: 700;
  margin-bottom: 20px;
}

.used-total-service .row-full-width span input {
  width: 100%;
}

.custom-membership-form .close-button-container {
  display: flex;
  justify-content: space-between;
}
