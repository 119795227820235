.customer-profile-container {
  display: flex;
  background-color: #f0f0f0;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding-top: 10px;
  height: 100%;
  margin-top: 165px;
}

.p-tabview .p-tabview-nav {
  background-color: transparent !important;
  border: 0px !important;
}

.p-tabview-nav {
  gap: 10px !important;
  justify-content: center;
}

.p-tabview-panels {
  display: none !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  border: 0px !important;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  background-color: #f0f0f0 !important;
}
.p-tabview .p-tabview-nav .p-tabview-ink-bar {
  background-color: transparent !important;
}

.p-column-filter-menu {
  margin-left: 0 !important;
}
.customer-tab-container {
  display: flex;
  flex-direction: column;
}
.customer-name {
  align-self: flex-start;
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-transform: capitalize;
  margin: 0;
  margin-left: 20px;
}

.save-profile-button-general-tap {
  margin-right: 40px;
  margin-top: 10px;
}
.customer-tab-container .customers-tabs {
  display: flex;
  align-items: flex-start;
  margin-left: 0px;
}
@media screen and (max-width: 1000px) {
  .customer-tab-container #layout {
    padding: 0px !important;
  }
  .customers-tabs {
    margin-left: 20px !important;
  }
  .customer-name {
    margin-left: 20px;
  }
  .customer-tab-container .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 10px !important;
    padding-left: 0 !important;
  }
  .save-profile-button-general-tap {
    margin-right: 0px;
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .customer-tab-container .p-tabview-nav {
    flex-wrap: wrap;
  }
  .orders-tab {
    padding-bottom: 10px;
  }
  .save-profile-button-general-tap {
    margin-top: 10px;
  }
}

.arabic-language .customer-tab-container textarea {
  padding-right: 10px !important;
}

.arabic-language .customer-tab-container .button-container {
  justify-content: flex-start;
}
