.promotion-codes-form {
  margin: 0 40px;
  height: fit-content;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.promotion-codes-form .form-input {
  border: 0 !important;
  border-radius: 0 !important;
  border-bottom: 1px solid #c4c4c4 !important;
}

.promotion-code-calendar {
  height: 27px;
  width: 100%;
  border-bottom: 1px solid #c4c4c4 !important;
}

.promotion-code-calendar .p-inputtext {
  border: 0 !important;
}
.title-close-button-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.p-selectbutton {
  font-size: 16px !important;
}

.p-selectbutton .p-button.p-highlight {
  background-color: #fcd601ac !important;
  border-color: #fcd601ac !important;
}

.promotion-codes-form input {
  width: 100%;
}

.promotion-codes-form #custom-button {
  height: fit-content;
  margin-bottom: 0;
}

@media screen and (max-width: 500px) {
  .promotion-codes-form .paid {
    gap: 20px;
    justify-content: space-evenly;
  }

  .promotion-codes-form {
    margin: 0;
  }

  .p-selectbutton {
    display: flex;
    gap: 10px;
  }

  .promotion-codes-form .p-button {
    border-radius: 6px !important;
  }

  .promotion-codes-form .form-title {
    font-size: 26px;
    margin: 0;
  }

  .promotion-codes-form #close-card-form-button {
    padding: 0px;
  }

  .promotion-codes-form .paid {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 340px) {
  .promotion-codes-form-container {
    height: 90%;
  }

  .promotion-codes-form .p-selectbutton {
    flex-wrap: wrap;
  }
}
