.general {
  display: flex;
  gap: 50px;
}

.general-tab-left-panel {
  background-color: #fff;
  height: fit-content;
  margin-left: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  box-shadow: 0px 0px 2px 2px rgb(0, 0, 0, 0.1);
  flex: 1;
}

.general-tab-left-panel .p-inputtext {
  box-shadow: 0px 0px !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #c4c4c4 !important;
  height: 28px !important;
  font-size: 16px;
  line-height: 20px;
}

.general-tab-left-panel .p-calendar {
  width: 47% !important;
}

.general-tab-right-panel {
  display: flex;
  margin: 0px 20px 0px 20px;
  flex-direction: column;
  gap: 30px;
  flex: 1;
}

.general-tab-right-header {
  display: flex;
  justify-content: space-between;
}

.p-inputtextarea {
  overflow-y: auto !important;
}
.save-profile-button-general-tap {
  margin-right: 20px;
}
.note-input-text {
  border: 0 !important;
  border-radius: 10px !important;
  width: 100%;
  box-shadow: 0px 0px 2px 2px rgb(0, 0, 0, 0.1);
  font-size: 20px;
  line-height: 20px;
  color: #555555;
  max-height: 300px;
}

.note-input-text::placeholder {
  font-size: 20px;
  line-height: 20px;
  color: #555555;
}
@media screen and (max-width: 1000px) {
  .customerGeneralTapLayout {
    width: 100%-40px;
    margin: 0 20px 0 20px;
  }
  .customerGeneralTapLayout .save-profile-button-general-tap {
    margin: 0;
  }
  .customerGeneralTapLayout .general-tab-right-panel {
    margin: 0;
  }
}
