.partners-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
}

.partners-form {
  width: 50%;
  height: fit-content;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.partners-form .phone-number-input {
  padding-left: 0;
}

.row-flex-end-partners-form {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0;
}

.partners-form .form-input {
  box-shadow: 0px 0px !important;
  border: 0 !important;
  border-radius: 0px !important;
  height: 28px !important;
  border-bottom: 1px solid #c4c4c4 !important;
}

.partners-form .note-input-text:enabled:focus {
  box-shadow: 0px 0px 1px 1px rgb(0, 0, 0, 0.3) !important;
}

.checkbox-container {
  margin-bottom: 15px;
}

.checkbox {
  margin-inline-end: 10px;
}

.checkboxs-header-label {
  font-size: 1rem;
  margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .partners-form {
    width: 70%;
  }

  .partners-form-container {
    padding: 20px 0px !important;
  }
}

@media screen and (max-width: 1000px) {
  .partners-form {
    width: 90%;
  }
}

@media screen and (max-width: 700px) {
  .partners-form .row {
    flex-direction: column;
  }
}

@media screen and (max-height: 750px) {
  .partners-form {
    margin-top: 10px;
    padding: 20px 40px;
  }
}
