.not-verified-container {
  display: flex;
  height: calc(100% - 156px);
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.verify-email-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  background-color: #fff;
  justify-content: center;
  padding: 80px 40px;
  border-radius: 15px;
  height: fit-content;
  width: 40%;
  text-align: center;
  margin-top: 100px;
}

.verify-email-button {
  color: #000 !important;
  font-weight: 600 !important;
  background: #fcd601ac !important;
  border: 0 !important;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}

.verify-email-button:hover {
  background: #fcd601ac !important;
  color: #000 !important;
}

.verify-email-image {
  position: relative;
  width: 40%;
}

.p-toast {
  z-index: 1000000 !important;
  margin-top: 150px;
}

.email-sent-icon {
  width: 50px;
}
