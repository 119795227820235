.service-form {
  min-width: 60%;
  width: fit-content;
  margin: 0 auto;
  height: fit-content;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.add-service-button {
  width: fit-content;
  padding: 12px 20px;
  border-radius: 30px;
  background: #fff;
  border: 1px solid #56ccf2;
  cursor: pointer;
  font-weight: 600;
  line-height: 20px;
  font-size: 13px;
  color: #56ccf2;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.disabled {
  cursor: not-allowed;
}

.labels-container {
  display: flex;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  color: #000;
  gap: 30px;
  width: 100%;
}

.labels-container > div {
  width: 100%;
  padding: 10px 0;
  font-weight: 700;
}

.close-button-form-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.service-form .p-error {
  position: relative !important;
  height: fit-content !important;
}

.service-form .column,
.column span {
  width: 100%;
}

.additional-night-prices-container .row-full-width {
  display: flex;
  flex-direction: column !important;
  gap: 8px;
}

.additional-night-prices-container input {
  width: 100%;
}

.service-form .hidden {
  visibility: hidden;
}

.service-form input[role="spinbutton"] {
  width: 100%;
}

.service-form .label {
  padding: 2px 0px;
  border-bottom: 0px !important;
}

.service-form .p-inputnumber-button-group {
  max-width: 50px !important;
  flex: 1 !important;
}

.service-form .p-button.p-button-icon-only {
  padding: 0 0px !important;
  cursor: pointer;
}

.service-form
  .p-inputnumber-buttons-stacked
  .p-inputnumber-button-group
  .p-button.p-inputnumber-button {
  width: auto;
}

.dash {
  width: auto !important;
  font-size: 0.8em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline-start: 20px;
}

.service-form .buttons-container {
  display: flex;
  flex-direction: row;
}

.service-form #minmax-buttons {
  text-align: end !important;
}
@media screen and (max-width: 550px) {
  .service-form {
    padding: 40px 20px;
  }
}

@media screen and (max-width: 415px) {
  .service-form .row,
  .labels-container {
    gap: 5px;
  }
}

@media screen and (max-height: 680px) {
  .service-form {
    padding: 10px 20px;
    gap: 15px;
  }
}
