.error-layout-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  padding-top: 150px;
}

.error-layout-container .p-button-rounded {
  background-color: #2f80ed !important;
  margin-top: 10px;
  color: #ffffff !important;
  box-shadow: 0px 0px !important;
}
