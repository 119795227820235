.partners-table-container .p-datatable .p-datatable-header {
  border: 0 !important;
  padding-right: 0 !important;
  display: flex !important;
  justify-content: flex-end !important;
  background-color: transparent !important;
}

.partners-list-main-column {
  width: 25% !important;
  text-transform: capitalize;
}

.partners-list-header {
  width: 50px !important;
}

.partners-table-container .p-selectable-row > td {
  width: 50px;
}

.partners-feature-not-allowed {
  visibility: hidden;
}
