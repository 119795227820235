.leaflet-container {
  width: 100%;
  position: relative;
}

.map-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 156px);
  direction: ltr;
}

.back-to-top-icon {
  display: none;
  position: fixed;
  z-index: 1000000000;
  margin-top: 200px;
  width: 50px;
  border-radius: 50%;
  bottom: 50px;
  right: 50px;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  animation: movebtn 3s ease-in-out infinite;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@media screen and (max-width: 600px) {
  .map-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: #fff;
    overflow-y: auto;
    height: fit-content;
  }
  .leaflet-container {
    height: 300px;
  }

  .orders-scroll {
    overflow: unset !important;
    height: fit-content !important;
  }

  .orders-container {
    min-height: fit-content !important;
  }

  .no-orders-message {
    margin-top: 100px;
    text-align: center;
  }
}
