#requests-table {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.requests-table-main-column {
  width: 170px;
}

.requests-header {
  width: 50px;
}

.requests-list-features-not-allowed {
  visibility: hidden;
}

.requests-list-dropdown {
  border: 0 !important;
  width: 30px !important;
  background-color: transparent !important;
}
