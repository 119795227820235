.sms-page {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.sms-main-section {
  width: 98%;
  margin: auto;
  background-color: #ffffff;
  height: auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 0px 2px 2px rgb(0, 0, 0, 0.1);
}
.sms-setup-section > * {
  margin-top: 20px;
}
.phone-input-field {
  font-size: 18px;
}
.sms-setup-section {
  width: 270px;
  display: flex;
  flex-direction: column;
}

.sms-phone-number-form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sms-enable-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.sms-enable-section > * {
  margin-top: 10px;
}
.the-whole-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0rem 1rem 0;
}

.submit {
  padding: 12px 20px;
  height: 44px;
  align-self: flex-end;
  margin: 0 1% 20px 0;
}
.add-button {
  height: 35px;
  margin: 0 0 20px 20px;
  align-self: flex-end;
}
button {
  width: fit-content;
  padding: 12px 20px;
  background: #56ccf2;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  border: 0;
}
.phone-number-form-container {
  display: flex;
  flex-direction: row;
}
.icon {
  width: 20px;
}
.phone-number-text {
  display: flex;
  align-items: baseline;
  align-items: center;
}
.phone-number-text > i {
  margin-left: 20px;
  padding: 20px;
  width: 10px;
  height: 10px;
  display: flex;
}
