.car-tab-container {
  display: flex;
  flex-direction: column;
}

.car-tab-container .car-card .row {
  display: flex;
  justify-content: space-evenly;
}
.no-car-text {
  align-self: center;
}
.cars-table .p-datatable-wrapper td {
  justify-content: flex-end !important;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
}

.cars-table .p-datatable-emptymessage td {
  justify-content: flex-start !important;
}

.cars-table .p-datatable-wrapper .cars-table-main-column {
  justify-content: flex-start !important;
}

.cars-list-row-action {
  width: 50px;
}

