.button-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.flexStart {
  justify-content: flex-start;
}

#custom-button {
  padding: 12px 20px;
  height: 44px;
  background: #56ccf2;
  border-radius: 5px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #ffffff;
  cursor: pointer;
  margin-bottom: 20px;
  border: 0;
}
