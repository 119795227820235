#trash-icon {
  cursor: pointer;
}

.promotion-codes-main-column {
  width: 200px;
}

.promotion-codes-header {
  width: 70px;
}

.promotion-code-type {
  text-transform: capitalize;
}
.expired {
  color: red;
}
