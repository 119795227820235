.add-customer-container {
  width: 70%;
  padding: 30px;
  position: relative;
  height: fit-content;
  background-color: #ffffff;
  box-shadow: 0px 0px 2px 2px rgb(0, 0, 0, 0.1);
  margin: 0 auto;
}

@media screen and (max-width: 800px) {
  .add-customer-container {
    width: 100%;
    padding: 0;
  }

  .add-customer-container .gender-buttons {
    flex-wrap: nowrap;
    gap: 10px;
  }
}

@media screen and (max-width: 400px) {
  .add-customer-container .gender-buttons {
    flex-wrap: wrap;
  }
}
