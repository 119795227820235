.orders-form-container {
  display: flex;
  gap: 30px;
  padding: 20px;
  overflow-y: auto;
  direction: ltr;
}

.order-form,
.feedback-form {
  height: fit-content;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 40px;
  flex: 1;
  margin: 0 auto;
}

.new-order .order-form {
  flex: 0.6 !important;
}

.image-uploader-container {
  display: flex;
  align-items: center;
}

.warranty-image {
  flex: 1;
  border-radius: 10px;
  cursor: pointer;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.warranty-image-container {
  width: 100px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.warranty-image-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  background-color: #00000050;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 5px;
  cursor: pointer;
}

@media screen and (max-height: 1610px) {
  .feedback-form {
    width: 70%;
  }
}

@media screen and (max-height: 830px) {
  .orders-form-container.feedback-form {
    gap: 10px;
    padding: 10px 40px;
    height: 535px;
    flex: 1;
  }

  .orders-form-container .order-form {
    flex: 1.5;
  }

  .orders-form-container .form-title {
    margin: 10px 0;
  }
}

.service-date-calendar {
  width: 100%;
  border-bottom: 1px solid #c4c4c4 !important;
  height: 26px;
}

.service-date-calendar > input {
  border: 0 !important;
}

.stars-row {
  justify-content: flex-start;
  gap: 0px;
}

.feed-back-form > button {
  display: flex;
  align-items: center;
  width: 25px;
  height: 25px;
}

.on,
.off {
  border: 0;
  background-color: transparent;
  cursor: pointer;

  padding-left: 0;
}
.on:hover {
  background-color: transparent;
}
.feedback-form-question {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
  margin-bottom: 20px;
}

.partners-dropdown .p-button.p-button-icon-only {
  position: absolute !important;
  right: 2px;
  bottom: -5px;
  color: #555555 !important;
}

.paid {
  justify-content: flex-start;
}

.paid span {
  color: #000 !important;
}

.paid .p-button {
  height: 26px !important;
  padding: 15px !important;
  color: #000 !important;
}

.paid .p-button:focus {
  box-shadow: 0px 0px !important;
}

.paid .p-togglebutton.p-button.p-highlight:hover,
.paid .p-togglebutton.p-button.p-highlight {
  background-color: #fcd601ac !important;
  color: #000;
  border-color: #fcd601ac;
}

.order-form .p-error {
  position: relative;
  top: 10px;
}

.order-form .close-button-container {
  justify-content: space-between;
}

.existed-order {
  height: 100%;
  overflow-y: auto;
}

.order-form .disabled,
.order-form .disabled::placeholder {
  color: #a7acb0;
  cursor: auto;
}

.cancelation-order-message {
  width: 30%;
  padding-left: 10px;
}

.css-yk16xz-control,
.css-1pahdxg-control {
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #c5c5c5 !important;
  box-shadow: 0 0 !important;
}

.location-picker-container {
  display: flex;
  border-bottom: 1px solid #c5c5c5 !important;
}

.location-picker-container .form-input {
  border-bottom: 0 !important;
}

.location-picker-container .location-picker {
  color: #000;
  border-radius: 50%;
  font-size: 20px;
  position: relative;
  right: 32px;
  bottom: 8px;
  cursor: pointer;
}

.arabic-language .order-form .location-picker-container .location-picker {
  right: -32px;
}

.location-picker-container .disabled {
  color: #c5c5c5;
}

#map {
  height: 180px;
}

.order-form-map {
  height: calc(100% - 130px) !important;
  overflow-y: hidden;
}

.order-map-close-button {
  cursor: pointer;
}

.order-form-dialog-map {
  width: 80vw;
  height: 80vh;
}

.location-search-autocomplete {
  position: relative;
  z-index: 3000000;
  margin: 10px 0;
  width: 100%;
}

.p-autocomplete-panel {
  z-index: 300000 !important;
}

.save-location-button {
  margin-top: 15px;
  padding: 0 30px !important;
}

.order-form input[role="spinbutton"] {
  width: 100%;
}

.order-form .form-title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.order-form .pi-copy {
  cursor: pointer;
  font-size: 20px;
  width: fit-content;
}

.p-tooltip {
  margin-left: 10px !important;
}

.locations-row {
  display: flex;
  align-items: center;
}

.locations-row .location-picker-container {
  width: 100%;
}
.locations-row .location-picker-container input {
  border: 0;
  padding: 0;
  padding-left: 10px;
}

.location-note-label {
  width: 180px;
}

.field {
  margin: 0 !important;
  width: 100% !important;
  padding: 0 !important;
}

@media screen and (max-width: 1100px) {
  .orders-form-container {
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    border-radius: 5px;
  }

  .feedback-form,
  .order-form {
    width: 80%;
    flex: 0;
  }

  .new-user-order {
    height: fit-content;
    overflow: auto;
    padding: 5px;
  }
}

.order-form-customer-name-container {
  margin: 30px 0 0;
}

.order-form-customer-name-container input {
  text-decoration: underline;
}

.order-form-customer-name-container .field .p-disabled {
  color: #0202d7 !important;
}

.order-form-customer-name-container .field {
  width: fit-content !important;
  display: flex;
  cursor: pointer !important;
}

.order-form-customer-name {
  font-size: 14px !important;
  color: #0202d7 !important;
  width: fit-content !important;
  height: 0 !important;
  border: 0 !important;
}

@media screen and (max-width: 725px) {
  .order-form .row {
    flex-direction: column;
  }

  .order-form .paid {
    flex-direction: row;
  }
}

@media screen and (max-width: 550px) {
  .order-form,
  .feedback-form {
    width: 100%;
  }
}

.arabic-language .orders-form-container {
  direction: rtl;
}
.customer-name-and-phone-icon-container {
  display: flex;
  flex-wrap: wrap;
  width: inherit;
  /* border: #000 solid; */
}
.source-icons {
  margin-top: auto;
  margin-right: 2.5%;
  margin-bottom: auto;
  margin-left: auto;
}
