.feedback-message {
  width: 100%;
}

.feedback-form .p-error {
  position: relative;
}

.send-feedback-button-disabled {
  cursor: not-allowed !important;
}

.arabic-language .feedback-form .field-checkbox {
  gap: 10px;
}

.arabic-language .feedback-form textarea {
  padding-right: 10px !important;
}
