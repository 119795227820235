.customers-table-container .p-inputtext {
  box-shadow: none !important;
}

#customers-table {
  font-size: 16px;
  line-height: 20px;
}

.customers-table-main-column {
  width: 18%;
}
.app-column {
  width: 12% !important;
}

.customers-list-action-column {
  width: 80px !important;
}

.customers-table-star-column {
  width: 0px !important;
}

.image-name-container {
  display: flex;
  gap: 10px;
  align-items: center;
}

.image-name-container .customers-img {
  width: 40px;
  height: 40px;
  border-radius: 40px;
}

.table-header-hidden {
  visibility: hidden;
}

.customers-table-export-csv-dropdown {
  background-color: transparent !important;
  border-bottom: 0px !important;
  width: fit-content;
}

.customers-table-export-csv-dropdown .p-dropdown-label-empty {
  width: 0 !important;
}

.customers-table-export-csv-dropdown .p-dropdown-trigger {
  border-radius: 50% !important;
  padding: 12px !important;
  width: fit-content !important;
}

.customers-list-icons-container {
  display: flex;
  width: 100%;
  justify-content: flex-end !important;
}
