.change-password-card-container {
  margin-top: 100px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  padding: 40px;
}

.change-password-card {
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin-top: 50px;
}

.change-password-image {
  width: 300px;
}

.change-password-fields-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  width: 300px;
  gap: 20px;
}

.change-password-fields-container .field,
.change-password-fields-container .p-inputtext {
  width: 300px !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.change-password-fields-container .field {
  border-bottom: 1px solid #c4c4c4 !important;
}

.reset-password {
  display: flex;
  gap: 10px;
}

.reset-password-link {
  font-size: 12px;
  text-decoration: underline;
  color: #0202d7;
  cursor: pointer;
}

.reset-password-link:hover {
  color: #0000ab;
}

.change-password-error {
  font-size: 12px;
  margin-top: 2px;
  padding-left: 2px;
  color: #e24c4c;
  visibility: hidden;
}

.error-shown {
  visibility: unset !important;
}

.button-disabled {
  background-color: grey !important;
  cursor: not-allowed !important;
}
