.memberships-table-main-colum {
  width: 45%;
}

.default-membership {
  width: 30px;
}

.membership-action-column {
  width: 120px;
}

.memberships-list-star-column {
  width: 40px;
}
.memberships-list-active-column {
  width: 70px;
  justify-content: center;
}
.memberships-list-features-not-allowed {
  visibility: hidden;
}

.invisible-element {
  visibility: hidden;
}
