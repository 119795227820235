@media screen and (max-width: 680px) {
  .administration-tab-container .p-tabview-nav {
    flex-wrap: wrap;
  }

  .administration-tab-container
    .p-tabview
    .p-tabview-nav
    li
    .p-tabview-nav-link {
    padding: 10px !important;
    padding-left: 0 !important;
  }

  .promotion-codes-tab {
    padding-bottom: 20px;
  }
}

.arabic-language .administration-tab-container .table-header {
  justify-content: flex-end;
}

.administration-main-column {
  width: 50%;
  text-transform: capitalize;
}

.administration-dropdown {
  min-width: 35px;
  height: 35px;
  border: 0 !important;
  border-radius: 50% !important;
}

.administration-table-layout td .p-dropdown {
  background-color: transparent !important;
}

.administration-table-layout td .p-dropdown .p-dropdown-trigger {
  position: absolute !important;
  top: 10px !important;
}

.administration-list-header {
  width: 70px !important;
}

.administration-main-column-date {
  width: 300px !important;
}

.administration-users-filter input,
.new-user-role {
  border-radius: 5px !important;
  border: 0 !important;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
  min-width: 180px !important;
}

.administration-users-filter input {
  width: 300px !important;
}
.role-add-button-container {
  gap: 10px;
}

.administration-table-layout .table-header {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.administration-table-layout .new-user-role {
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  height: 44px !important;
  width: fit-content;
}

.users-filter-container .p-autocomplete {
  display: inline !important;
}

.administration-table-layout .button-container {
  justify-content: flex-start !important;
}

.arabic-language .administration-table-layout .table-header,
.arabic-language .administration-table-layout .table-header .button-container {
  justify-content: flex-end !important;
}

.arabic-language
  .administration-table-layout
  .table-header
  .role-add-button-container {
  justify-content: flex-end !important;
  width: fit-content;
}

.sms-toggle-button {
  min-width: 100px;
}

.sms-label {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.9);
  white-space: nowrap;
}
.last-updated-time {
  text-align: center;
  font-size: 0.8rem;
  position: relative;
  bottom: 10px;
  left: 0;
  right: 0;
}

@media screen and (max-width: 615px) {
  .arabic-language .administration-table-layout .table-header {
    flex-direction: column-reverse;
    align-items: flex-end !important;
  }
}
