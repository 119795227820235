.profile-page {
  display: flex;
  justify-content: center;
}

.profile-page-container {
  margin-top: 40px;
  background-color: #ffffff;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
}

.languages-container {
  position: absolute;
  bottom: 20px;
  display: flex;
  gap: 10px;
  direction: ltr;
}

.languages-container small {
  text-decoration: underline;
  cursor: pointer;
}

.languages-container .active-language {
  color: blue;
}
