.car-form-container {
  background-color: #ffffff;
  height: fit-content;
  padding: 20px;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 60%;
  flex-wrap: wrap;
}

.car-form-container .row {
  display: flex;
  justify-content: space-between;
}

.car-card-input {
  height: 28px;
  background: #ffffff;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  font-size: 16px;
  color: #555555;
}

.car-card-input:focus {
  outline: none;
}

.close-button-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

#close-card-form-button {
  cursor: pointer;
  position: relative;
  right: 0px;
  padding: 10px;
}

#fuelType {
  font-size: 16px;
  line-height: 20px;
  color: #555555;
  padding-left: 10px;
  align-self: flex-start;
}

.fuel-row {
  flex-direction: column;
  gap: 10px;
}

.fuel-row button {
  padding: 12px 30px;
  height: 44px;
  border-radius: 20px;
  border: 0;
  text-align: center;
  cursor: pointer;
  background-color: #f1f1f1;
  color: #555555;
}

.car-form-container .fuel-type-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  padding: 0;
  flex-wrap: wrap;
}

.fuel-type-buttons .selected {
  color: #004a60;
  background: #ffd800;
  transition: 1s;
}

.models-dropdown-item {
  color: #000;
  font-size: 15px;
  line-height: 20px;
}

.p-autocomplete-item {
  border-bottom: 1px solid #ced4da !important;
}

#car-form-year-picker {
  width: 100%;
}

.car-form-container #car-form-year-picker > input {
  border: 0 !important;
  border-bottom: 1px solid #ced4da !important;
  height: 26px !important;
  border-radius: 0 !important;
}

.car-form-container .p-autocomplete {
  height: 26px !important;
}

.car-form-container .p-autocomplete > button {
  position: absolute !important;
  right: 0 !important;
  bottom: 0px !important;
}

.car-form-container .p-autocomplete > button > span {
  color: #6c757d !important;
}

.p-fileupload-choose {
  background-color: #ffd800 !important;
  border-color: #ffd800 !important;
  color: #000 !important;
}

.registration-image-upload-container {
  display: flex;
  gap: 10px;
  padding-left: 10px;
  flex-direction: column;
  margin-bottom: 20px;
}

.registration-file .p-button {
  padding: 8px 20px;
  box-shadow: 0 0 !important;
  font-size: 14px;
}

.registration-image {
  width: 100px;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  object-fit: cover;
}

.registration-image-container {
  width: 100px;
  height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.registration-image-link {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 12.5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
}

.edit-car-loader-container {
  margin-top: 200px;
}

@media screen and (max-width: 1000px) {
  .car-form-container {
    width: 80%;
  }
}

@media screen and (max-width: 640px) {
  .car-form-container {
    width: 100%;
  }
}

.arabic-language .car-form-container .p-autocomplete > button {
  left: 0;
  right: auto !important;
}

.arabic-language .p-button.p-fileupload-choose {
  flex-direction: row-reverse;
}
