.orders-container {
  min-width: 320px;
  min-height: calc(100vh - 156px);
  background-color: #fff;
}

.today-order-container {
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  gap: 10px;
}

.order-time {
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
}

.orders-scroll {
  overflow-y: scroll;
  white-space: nowrap;
  height: calc(100vh - 216px);
  justify-content: center;
}

.center {
  text-align: center;
}

.today-order-container .row {
  gap: 10px;
  justify-content: center;
}

.order {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
}

.open .p-inputtext {
  color: #fff !important;
  font-weight: 700;
  background: red !important;
}

.canceled .p-inputtext {
  color: #fff !important;
  font-weight: 700;
  background: grey !important;
}

.inprogress .p-inputtext {
  color: #fff !important;
  font-weight: 700;
  background: orange !important;
}

.assigned .p-inputtext {
  color: #000 !important;
  font-weight: 700;
  background: lightblue !important;
}

.completed .p-inputtext {
  color: #fff !important;
  font-weight: 700;
  background: green !important;
}

.today-order-dropdown .p-inputtext {
  border-radius: 0px !important;
}

.today-order-dropdown li {
  text-align: center !important;
}

.orders-container .p-dropdown {
  border: 0 !important;
  border-bottom: 0 !important;
}

.today-order-dropdown .p-dropdown-trigger {
  display: none;
}

.today-order-dropdown span {
  justify-content: center !important;
}

.today-order-container .column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 10px 10px;
}

#today-orders-partner {
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.2) !important;
}

#today-orders-partner span {
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 400 !important;
}

.no-orders {
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-orders-message {
  font-size: 16px;
}

.today-order-clickable-area {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
  cursor: pointer;
}

.today-order-toast-message-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 20px;
}

.today-order-toast-message-container button {
  margin-top: 20px !important;
  background-color: #ff5757 !important;
  border: 0 !important;
  color: #fff !important;
}
.name-row {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 0 20px 0 20px;
  align-items: center;
}
.name-row > * {
  margin: 0 5px 0 0;
}
.full-name {
  white-space: normal;
}
