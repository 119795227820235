.App {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.div_centered {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.sign-in-up-page-container {
  min-height: 100%;
  background-color: #fcd601ac;
  display: flex;
  align-items: center;
}

.sign-in-up-header {
  flex: 1;
  display: flex;
  justify-content: center;
}

#sign-in-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 80px;
  width: 180px;
}

.sign-in-up-link {
  font-size: 12px;
  margin: 20px;
}

.sign-in-up-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: 100vh;
  background-color: #ffffff;
}

.sign-in-up-form-inputs-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.sign-in-up-form .p-inputtext {
  width: 300px;
}

.sign-in-up-form .p-inputtext:enabled {
  box-shadow: 0px 0px 5px 5px rgb(0, 0, 0, 0.1) !important;
}

.error-container {
  width: 300px;
  display: flex;
  position: relative;
  height: 15px;
}

@media screen and (max-width: 750px) {
  .sign-in-up-form .p-inputtext,
  .error-container {
    width: 60%;
  }
}

@media screen and (max-width: 560px) {
  .sign-in-up-page-container {
    flex-direction: column;
    position: absolute;
    width: 100%;
  }

  .sign-in-up-form {
    width: 100%;
  }

  .sign-in-up-header {
    align-items: center;
  }

  .sign-in-up-form-inputs-container {
    gap: 10px;
  }
}

.submitBtn {
  font-size: 14px !important;
  line-height: 20px !important;
  background: #fcd601ac !important;
  color: #000 !important;
  border: 0 !important;
  cursor: pointer;
  box-shadow: 0px 0px 2px 2px rgb(0, 0, 0, 0.2) !important;
  padding: 10px 20px !important;
}

.sign-in-up-form .show {
  opacity: 1;
}

.sign-in-up-form .hide {
  opacity: 0;
}

.sign-in-up-error {
  color: red;
  font-size: 12px;
}

.p-tabview {
  margin-bottom: 10px !important;
}

.p-dropdown {
  width: 100%;
  border: 0 !important;
  line-height: 0 !important;
  border-radius: 0px !important;
  border-bottom: 1px solid #c4c4c4 !important;
}

.p-dropdown:not(.p-disable).p-focus {
  box-shadow: 0px 0px !important;
}

.p-dropdown-label {
  display: flex !important;
  box-shadow: 0px 0px !important;
  align-items: center !important;
}

.p-button-icon-only {
  color: #56ccf2 !important;
  background-color: transparent !important;
  border: 0px !important;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  align-items: center;
}

.p-datatable .p-datatable-tbody > tr {
  min-height: 60px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 10px !important ;
}

.p-datatable-scrollable .p-datatable-thead > tr > th,
.p-datatable-scrollable .p-datatable-tbody > tr > td,
.p-datatable-scrollable .p-datatable-tfoot > tr > td {
  flex: auto !important;
}

.form-input {
  width: 100%;
  background: #ffffff;
  border: 0 !important;
  border-bottom: 1px solid #c4c4c4 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
  padding-left: 10px;
  border-radius: 0 !important;
}

.form-input:focus {
  outline: none !important;
}

.form-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 20px;
  color: #555555;
  margin: 20px 0px;
  text-align: left;
}

input {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  padding-left: 10px;
}

.p-inputtext:enabled:focus {
  box-shadow: 0px 0px !important;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #ffd800 !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background-color: #ffd800 !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: 0 0 !important;
}

.p-dialog-mask {
  z-index: 200000 !important;
}

.PhoneInputInput,
.PhoneInputInput:focus-visible {
  border: 0;
  outline: 0;
}

.p-error {
  font-size: 12px;
  margin-top: 2px;
  padding-left: 2px;
}

.p-autocomplete {
  width: 100%;
}

.p-autocomplete-input {
  width: 100% !important;
  border: 0 !important;
  border-bottom: 1px solid #ced4da !important;
  border-radius: 0 !important;
  height: 27px !important;
}

.p-datatable .p-datatable-thead > tr > th {
  padding-left: 10px !important;
}

.p-datepicker {
  z-index: 100000 !important;
}

*::-webkit-scrollbar {
  width: 10px;
  position: relative;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #ffd800;
  border-radius: 15px;
  border: 3px solid #ffffff;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  margin: 0 !important;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail {
  margin: 0.5rem !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
  box-shadow: 0px 0px !important;
}

.integer-only {
  height: 26px !important;
}

.integer-only > input {
  border: 0;
  padding-left: 0px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.table-header input {
  height: 44px !important;
  min-width: 300px;
}

.table-header #custom-button {
  margin: 0px !important;
}

@media screen and (max-width: 550px) {
  .table-header {
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 10px;
  }

  .table-header > input {
    min-width: 100%;
  }
}

@media screen and (max-width: 615px) {
  .administration-tab-header {
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 0 !important;
}

.p-dialog {
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1) !important;
  z-index: 1000000 !important;
}

.p-dialog .p-button {
  background: #fcd601ac !important;
  border: 0 !important;
  color: #000 !important;
  box-shadow: 0px 0px !important;
}

.p-menuitem-text {
  font-weight: 700;
}

button[aria-label="Apply"],
button[aria-label="Clear"] {
  color: #000000 !important;
  border: 1px solid #fcd601ac !important;
  box-shadow: 0px 0px !important;
}

button[aria-label="Apply"] {
  background: #fcd601ac !important;
}

button[aria-label="Clear"] {
  background-color: #ffffff !important;
}

.user-item {
  display: flex;
  flex-direction: column;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.p-toast {
  opacity: 1 !important;
  max-width: fit-content !important;
}

.p-toast-message {
  font-weight: 600 !important;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
}

.p-toast .p-toast-message.p-toast-message-success {
  background-color: #fff !important;
}

@media screen and (max-width: 725px) {
  .p-tabview-nav {
    justify-content: unset !important;
  }
}

.p-datatable-scrollable .p-datatable-wrapper::-webkit-scrollbar {
  height: 12px !important;
}

.p-tabview .p-tabview-nav {
  justify-content: center !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
  color: #2f80ed !important;
}

.p-tabview-nav a[aria-selected="true"] span {
  border-bottom: 2px solid #2f80ed !important;
  padding-bottom: 10px;
}

input:disabled,
button:disabled,
input:disabled::placeholder,
.p-disabled,
.p-disabled .p-dropdown-label,
.p-disabled .p-dropdown-trigger {
  color: #a0a0a0 !important;
  border-color: #c4c4c4 !important;
  opacity: 1 !important;
}

#popup_menu {
  min-width: fit-content !important;
}

.csv-dropdown-container {
  display: flex;
  width: 100%;
}

input,
.p-dropdown .p-dropdown-label,
.p-inputnumber {
  padding-left: 0 !important;
}

label {
  left: 0 !important;
  padding-left: 0 !important;
}

.table-header .p-inputtext {
  padding-left: 10px !important;
}

.pi-trash {
  color: red;
}

.padded-input-text {
  padding-left: 10px !important;
}

.p-selectbutton {
  direction: ltr !important;
}

.arabic-language #layout {
  direction: rtl;
}

.arabic-language .p-datatable-wrapper,
.orders-form-container {
  direction: rtl;
}

.arabic-language label {
  right: 0 !important;
}

.arabic-language .p-inputtext {
  padding-right: 0px !important;
}

.arabic-language .table-header .p-inputtext {
  padding-right: 10px !important;
  direction: rtl;
}

.arabic-language .PhoneInputCountry {
  gap: 10px;
}

.arabic-language .p-datatable .p-column-header-content {
  flex-direction: row-reverse;
  justify-content: flex-end;
}

.arabic-language .p-datatable .p-datatable-tbody > tr > td {
  text-align: right;
}

.arabic-language .p-togglebutton {
  flex-direction: row-reverse;
}

.arabic-language .user-orders .table-header {
  flex-direction: row-reverse !important;
}

.arabic-language .p-autocomplete-loader {
  left: 10px !important;
  right: auto !important;
}
