.orders-table .p-datatable-wrapper td {
  justify-content: flex-end !important;
  font-size: 16px;
  line-height: 20px;
  color: #555555;
}

.orders-table .p-datatable-emptymessage td {
  justify-content: flex-start !important;
}

.orders-table .p-datatable-wrapper .orders-table-main-column {
  justify-content: flex-start !important;
}

.orders-list-row-action {
  width: 50px;
}

.p-column-filter-overlay {
  width: 230px !important;
}

.p-dropdown-clear-icon {
  display: none !important;
}

.orders-table-star-column {
  width: 0;
}

.user-orders {
  height: fit-content !important;
}

.orders-list-status-column-item {
  width: 120px;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.item-Open {
  color: #fff;
  background-color: red;
}

.item-Canceled {
  color: #fff;
  background-color: grey;
}

.item-Completed {
  color: #fff;
  background-color: green;
}

.item-Assigned {
  color: #000;
  background-color: lightblue;
}

.item-In {
  color: #fff;
  background-color: orange;
}
.custom-badge {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, 50%);
  background-color: #fff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 20px;
  width: 20px;
  border: 1px solid black !important;
}
.p-badge {
  min-width: 0.3rem !important;
  height: 0.3rem !important;
  align-self: center;
  align-items: center;
  align-content: center;
}
.custom-avatar {
  position: relative;
  margin-right: 10px;
}
.phone-icon {
  margin: 1px 0 1px 0;
}
