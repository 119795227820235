.customer-membership-form {
  width: 60%;
  margin: 30px auto;
  height: fit-content;
  padding: 40px;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.customer-memberships-table .customer-membership-row-name {
  white-space: pre;
}

.customer-membership-section {
  width: 100%-40px;
  margin: 0 20px 0 20px;
}
.p-datatable .p-datatable-tbody > tr {
  min-height: 60px !important;
}

.number-of-times-left-input input,
.number-of-times-left-label {
  padding: 0px 5px !important;
  width: 100px;
  height: 25px;
}

.customer-membership-buttons-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.membership-action-button {
  margin: 10px 0px 15px 15px !important;
}

@media screen and (max-width: 650px) {
  .customer-membership-form {
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .customer-membership-form {
    width: 100%;
    margin: 0;
  }
}
.add-customer-membership-dropdown-container {
  display: flex;
  justify-content: flex-end;
}

.add-customer-membership-dropdown {
  width: 250px;
  height: 40px;
  border: 0 !important;
  margin-bottom: 10px;
  border-radius: 5px !important;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1) !important;
}
